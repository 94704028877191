/*
 * Blog Details
 */

.mi-blog-details {
  img {
    margin-bottom: 15px;
  }
  h3 {
    font-weight: 700;
  }
  blockquote {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
