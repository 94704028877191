/**
 * Button
 */

.mi-button {
  position: relative;
  padding: 0 30px;
  background: $color-theme;
  color: #ffffff;
  border: 0;
  display: inline-block;
  z-index: 1;
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 2px;
  height: $input-button-height;
  line-height: $input-button-height;
  transition: $transition;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: auto;
    bottom: 0;
    height: 2px;
    width: 100%;
    background: #ffffff;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: $transition;
  }

  &:hover {
    color: #ffffff;
  }

  &:hover::before {
    transform: scaleX(1);
  }

  @media #{$layout-mobile-lg} {
    height: $input-button-height - 5;
    line-height: $input-button-height - 5;
  }
}

.mi-readmore {
  position: relative;
  color: $color-theme;
  transition: $transition;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: auto;
    bottom: 0;
    height: 1px;
    width: 0;
    background: $color-theme;
    transition: $transition;
  }

  &:hover {
    color: $color-theme;

    &::after {
      width: 100%;
    }
  }
}
